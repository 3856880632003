import '../style/header.scss'
import '../style/footer.scss'
import '../style/reset.scss'


$('.products-new .exhibit-list li').mouseenter(function(){
  let src = $(this).attr("src")
  $('.products-new .product-type img').attr('src', src)
  
})

$('.industry-new .exhibit-list li').mouseenter(function(){
  let src = $(this).attr("src")
  console.log(src);
  $('.industry-new .product-type img').attr('src', src)
  
})


$('.accessories li').mouseenter(function(){
  let src = $(this).attr("src")
  $('.accessories img').attr('src', src)
  
})

$('.manage li').mouseenter(function(){
  let src = $(this).attr("src")
  $('.manage img').attr('src', src)
  
})
